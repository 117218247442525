import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import logo from "../../../assets/images/logo/96-96.svg"
import AuthService from "../../../services/auth-service"
import Button from "../../atoms/button/button"
import Input from "../../atoms/input/input"
import "./login.scss"
import ReCAPTCHA from "react-google-recaptcha"
import * as reCaptchaKey from "../../../constants/reCaptcha"
import userSessionStorage from "../../../services/userSessionStorage"
import { isAdmin, isAuthorized } from "../../../utils/getRol"

export default () => {

  const recaptchaRef = useRef(null)
  useEffect(() => {
    recaptchaRef.current.execute()
  }, [recaptchaRef])

  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [error, setError] = useState("")
  const [loadLogin, setLoadLogin] = useState(false)
  const session = new userSessionStorage()

  useEffect(() => {
    if (sessionStorage.getItem("userSession") !== null || session.getToken() !== null) {
      navigate("/")
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [])


  const handleLoginRequest = async e => {
    e.preventDefault()
    setLoadLogin(true)

    let payload = {
      // user: user.trim(),
      email: user ? user.replace(/\s+/g, "") : "",
      password: password,
      captcha: recaptchaRef.current.getValue(),
    }


    // COGNITO LOGON
    /*
        const res = await AuthService.authentication(payload)
        // console.info(
        //   "AUTH SERVICE",
        //   AuthService.authentication(payload).then(res => res)
        // )
        if (res === "Incorrect username or password.") {
          setError("*Usuario y/o contraseña incorrectas")
        }
        if (res === "Missing required parameter USERNAME") {
          setError("*Debes ingresar tu nombre de usuario")
        }
        console.info("AUTH SERVICE", res)*/

    session.authLogin({
      payload,
      handleSuccess: res => {
        session.authUserInfo({
          handleSuccess: res => {
            setLoadLogin(false)
            let idToken = JSON.parse(localStorage.getItem("TOKEN_INFO"))?.idToken

            if (isAuthorized(idToken)) {
              // console.log("Login correcto")
              setLoadLogin(false)
              navigate("/")
            } else {
              session.destroySession()
              setError("*Acceso no autorizado")


            }

          },
          handleError: res => {
            // console.log("error Correct login")
            session.destroySession()
            setError("*Usuario y/o contraseña incorrectas")
            setLoadLogin(false)
          },
        })
      },
      handleError: res => {
        setError("*Usuario y/o contraseña incorrectas")
        setLoadLogin(false)
      },
      handleFinally: () => {
        recaptchaRef.current.execute()
      },
    })


  }


  return (
    <>
      {isLoggedIn ? (
        <></>
      ) : (
        <div className="login">
          <div className="login__header">
            <img className="logo" alt="Logo Nosotras" src={logo} />
          </div>
          <div className="login__content">
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={reCaptchaKey.key}
            />
            <div className="login__image">
              <div className="login__text">
                <span>Banco de imágenes</span>
                <span>Nosotras</span>
              </div>
            </div>
            <div className="login__form">
              <h1>¡Bienvenido!</h1>
              <p>En este espacio encontrarás los recursos de la marca que necesitas. Ingresa acá con tus datos
                registrados en NosotrasOnline.com.</p>
              <form className="form">
                <label>Tu usuario</label>
                <Input
                  placeholder="Ingresa tu usuario"
                  onChange={e => {
                    setError("")
                    setUser(e)
                  }}
                />
                <label>Tu contraseña</label>
                <Input
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  onChange={e => {
                    setError("")
                    setPassword(e)
                  }}
                />
                <span className="error-message">{error}</span>

                <Button
                  styleType="primary"
                  name="Ingresar"
                  onClick={handleLoginRequest}
                  disabled={loadLogin}
                />
                <p className="login__familia">O si quieres ingresa <a
                  href="https://fam-adf-pdn.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=adfs&redirect_uri=https://bancoimagenes.grupofamilia.com/&response_type=TOKEN&client_id=hbpr775hc2cu86hpivokcn8kr&scope=email openid"
                >
                  aquí
                </a> con tu cuenta de Grupo Familia</p>

              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
