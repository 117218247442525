import React, { useEffect } from "react"
import LoginComponent from "../components/organisms/login/login"
import SEO from "../components/seo"
import { deleteEntry } from "../services/CMS"
import "../styles/index.scss"

const LoginPage = () => {

  useEffect(() => {
    deleteEntry({ entryId: "5BB85tnQUM7dp59RdrY53G" })

    return () => { }
  }, [])

  return (
    <>
      <SEO lang="es" title="Home" />
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <LoginComponent />
      </div>
    </>
  )
}

export default LoginPage;